import React, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import Input from '../FormElements/Input';
import Select from '../FormElements/Select';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinnerCenter from '../UIElements/Loading/LoadingSpinnerDark';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import styles from './CreateLiveStream.module.css';
import './CreateEventForm.css';
import EventTypeCard from '../../../event/components/EventTypeCard';

const CreateEventForm = ({ success }) => {
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_PUBLIC_URL } = process.env;
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [timezone, setTimezone] = useState('0');
  const [type, setType] = useState();
  const [types, setTypes] = useState([]);
  const [step, setStep] = useState(0);

  // getting set types from env
  //const types =  `${REACT_APP_EVENT_TYPES}`.split("&");

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/event/event-types`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        console.log(response);
        if (response.success) {
          setTypes(response.eventTypes);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchTypes();
  }, []);

  const onSubmit = async event => {
    event.preventDefault();
    clearError();
    console.log(event.target.uri.value);
    //do some validation checks here.. for event create data

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/event/create/${type}`,
        'POST',
        JSON.stringify({
          title: event.target.title.value,
          subTitle: event.target.subTitle.value,
          type: type,
          uri: event.target.uri.value,
          supportEmail: event.target.supportEmail.value,
          date: event.target.date.value,
          timeZone: timezone,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(response);
      success();
    } catch (err) {
      console.log(err);
    }
  };

  const changeTimeZoneHandler = event => {
    console.log(event.target.value);
    setTimezone(event.target.value);
  };

  return (
    <div className="create-event-form-wrapper">
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && !type && (
        <div className="typeListWrapper">
          {types.map(eventType => (
            <EventTypeCard key={eventType.id} type={eventType} selected={setType}/>
          ))}
        </div>
      )}
      {!isLoading && type && (
        <form onSubmit={onSubmit}>
          <Input
            id="title"
            name="title"
            type="text"
            placeholder="required"
            groupFront="Event Title:"
          />
          <Input
            id="subTitle"
            name="subTitle"
            type="text"
            placeholder="optional"
            groupFront="Event Sub Title:"
          />
          <Input
            id="supportEmail"
            name="supportEmail"
            type="email"
            placeholder="required"
            groupFront="Support Email:"
          />
          <label className="event-uri-label">
            This Cannot be changed! must be lowercase without spaces
          </label>
          <Input
            id="uri"
            name="uri"
            type="text"
            placeholder="Event URI"
            groupFront={`${REACT_APP_PUBLIC_URL}/`}
          />
          <div className={styles.actions}>
            <Select
              id="timezone"
              name="timezone"
              className={styles.timeZone}
              groupFront="Timezone:"
              onChange={changeTimeZoneHandler}
              value={timezone}
            >
              <option value="-12">Baker Island -12:00</option>
              <option value="-11">American Samoa -11:00</option>
              <option value="-10">Honolulu -10:00</option>
              <option value="-9">Anchorage -09:00</option>
              <option value="-8">Los Angeles -08:00</option>
              <option value="-7">Denver -07:00</option>
              <option value="-6">Mexico City -06:00</option>
              <option value="-5">New York -05:00</option>
              <option value="-4">Halifax -04:00</option>
              <option value="-3">Buenos Aires -03:00</option>
              <option value="-2">South Sandwich Islands -02:00</option>
              <option value="-1">Azores -01:00</option>
              <option value="0">London GMT 00:00</option>
              <option value="+1">Paris, + 01:00</option>
              <option value="+2">Johannesburg + 02:00</option>
              <option value="+3">Moscow + 03:00</option>
              <option value="+4">Dubai + 04:00</option>
              <option value="+5">Maldives + 05:00</option>
              <option value="+6">Dhaka + 06:00</option>
              <option value="+7">Bangkok + 07:00</option>
              <option value="+8">Perth + 08:00</option>
              <option value="+9">Tokyo + 09:00</option>
              <option value="+10">Sydney + 10:00</option>
              <option value="+11">Vanuatu + 11:00</option>
              <option value="+12">Auckland + 12:00</option>
              <option value="+13">Samoa + 13:00</option>
              <option value="+14">Kiribati + 14:00</option>
            </Select>
          </div>
          <Input
            id="datetime"
            name="date"
            type="date"
            groupFront="Event Date:"
          />
          <div className="create-event-actions">
            <Button title="create" className="button-secondary" />
          </div>
        </form>
      )}
      {isLoading && <LoadingSpinnerCenter />}
    </div>
  );
};

export default CreateEventForm;
