import  { createContext } from 'react';

export const EventContext = createContext({
  eventId: null,
  title: null,
  subTitle: null,
  type: null,
  uri:null,
  date:null,
  days:null,
  open:false,
  supportEmail: null,
  lobbyKuulaCode: null,
  networkingKuulaCode: null,
  exhibitionKuulaCode: null,
  eventConfig: null,
  createEvent: () => {},
  clearEvent: () => {},
});
