import React, { useEffect, useState } from 'react';

import styles from './AvatarIcon.module.css';

const AvatarIcon = ({ user }) => {
  const { REACT_APP_API_URL } = process.env;
  const [initials, setInitials] = useState();
  const [useAvatar, setUserAvatar] = useState();

  useEffect(() => {
    user.firstName &&
      user.lastName &&
      setInitials(`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`);
    user.avatar && setUserAvatar(REACT_APP_API_URL + '/' + user.avatar);
  }, [user.firstName, user.lastName, user.avatar]);

  return (
    <div id={styles.avatar}>
   
        {useAvatar ? (
          <img className={styles.image} src={useAvatar} alt="avatar" />
        ) : (
          <h1
            className={styles.text}
            style={{ textTransform: 'uppercase', textAlign: 'center' }}
          >
            {initials ? initials : '?'}
          </h1>
        )}
    
    </div>
  );
};

export default AvatarIcon;
