import { useState, useCallback, useEffect } from 'react';

export const useEvent = () => {
  const [eventId, setEventId] = useState(null);
  const [title, setTitle] = useState(null);
  const [subTitle, setSubTitle] = useState(null);
  const [type, setType] = useState(null);
  const [uri, setUri] = useState(null);
  const [date, setDate] = useState(null);
  const [days, setDays] = useState(null);
  const [open, setOpen] = useState(false);
  const [lobbyKuulaCode, setLobbyKuulaCode] = useState(null);
  const [networkingKuulaCode, setNetworkingKuulaCode] = useState(null);
  const [exhibitionKuulaCode, setExhibitionKuulaCode] = useState(null);
  const [eventConfig, setEventConfig] = useState();
  const [supportEmail, setSupportEmail] = useState(null);
  
  const createEvent = useCallback((
    id, 
    title, 
    subTitle,
    type, 
    uri, 
    date, 
    days,
    open, 
    supportEmail, 
    lobbyKuulaCode, 
    networkingKuulaCode,
    exhibitionKuulaCode,
    eventConfig ) => {

    setEventId(()=>id);
    setTitle(()=>title);
    setSubTitle(()=>subTitle);
    setType(()=>type);
    setUri(()=>uri);
    setDate(()=>date);
    setDays(()=>days);
    setOpen(()=>open);
    setSupportEmail(()=>supportEmail);
    setLobbyKuulaCode(()=>lobbyKuulaCode);
    setNetworkingKuulaCode(()=>networkingKuulaCode);
    setExhibitionKuulaCode(()=>exhibitionKuulaCode);
    setEventConfig(()=>eventConfig)
    console.log('setting Event Context');
    
    localStorage.setItem(
        'eventData',
        JSON.stringify({
          eventId: id,
          title: title,
          subTitle: subTitle,
          type: type,
          uri: uri,
          date:date,
          days:days,
          open: open,
          supportEmail: supportEmail,
          lobbyKuulaCode:lobbyKuulaCode,
          networkingKuulaCode:networkingKuulaCode,
          exhibitionKuulaCode: exhibitionKuulaCode,
          eventConfig: eventConfig
          
        })
      );
    },
    []
  );

  const clearEvent = useCallback(() => {
    setEventId(null);
    setTitle(null);
    setSubTitle(null);
    setType(null);
    setUri(null);
    setDate(null);
    setDays(null);
    setOpen(null);
    setSupportEmail(null);
    setLobbyKuulaCode(null);
    setNetworkingKuulaCode(null);
    setExhibitionKuulaCode(null);
    setEventConfig(null)
    localStorage.removeItem('eventData');
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('eventData'));
    storedData && createEvent(
    storedData.eventId,
    storedData.title,
    storedData.subTitle,
    storedData.type,
    storedData.uri,
    storedData.date,
    storedData.days,
    storedData.open,
    storedData.supportEmail,
    storedData.lobbyKuulaCode,
    storedData.networkingKuulaCode,
    storedData.exhibitionKuulaCode,
    storedData.eventConfig,
   
    );
    
  }, [createEvent]);

  return { eventId, title, subTitle, type, uri, date, days, open, supportEmail, lobbyKuulaCode, networkingKuulaCode, exhibitionKuulaCode,  eventConfig,  createEvent, clearEvent };
};
