import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/Loading/LoadingSpinnerDark';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './Auth.css';
import Logo from '../../shared/components/UIElements/logo';
import VersionNumber from '../../shared/components/versionNumber/VersionNumber';

const Auth = props => {
  const { REACT_APP_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const navigation = useNavigate();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const submitHandler = async event => {
    event.preventDefault();

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/auth/control-panel`,
        'POST',
        JSON.stringify({
          email: event.target.email.value,
          password: event.target.password.value,
        }),
        {
          'Content-Type': 'application/json',
        }
      );

      if (response) {
        if (response.userId) {
          auth.login(
            response.userId,
            response.email,
            response.firstName,
            response.lastName,
            response.avatar,
            response.lang,
            response.token
          );
          navigation('/');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="auth_bg"></div>
      <div className="auth_mesh"></div>
      <div className="wrapper center col">
        <div className="auth-wrapper">
          <Card className="login-card" style={{ width: '100%' }}>
            <div className="auth-branding-container">
            <div className='auth-logo-wrapper'>
            <Logo imgClassName="auth-logo" white />
            </div>
             
              <div className='auth-branding-titles'>
                <h1>Control Panel</h1>
                <p>Hybrid Digital Events</p>
              </div>
            </div>

            {isLoading ? (
              <LoadingSpinner loaderThemeDark />
            ) : (
              <form onSubmit={submitHandler}>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="email"
                  inputClassName="no-group form-padding auth-input"
                />
                <Input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  inputClassName="no-group form-padding auth-input"
                />
                <Button
                  type="submit"
                  styleType='secondaryLarge'
                  title="Login"
                />
              </form>
            )}
          </Card>
        </div>
      </div>
      <VersionNumber />
    </React.Fragment>
  );
};

export default Auth;
